.receipt{
    border-collapse: collapse;
    margin: 0 auto;
    padding: 0;
    max-width: 600px;
}

.logo{
    text-align:center;
    padding:20px;
}

.socials{
    text-decoration: none !important;
    padding: 10px;
}

div.rating-label.checked,
label.rating-label {
    & > i,
    & > .svg-icon {
        color: var(--kt-primary);
    }
    & > .bi-suit-heart::before{
        content: "\f59d" !important;
   }
}

.rating-input:checked ~ .rating-label {
    & > i,
    & > .svg-icon {
        color: var(--kt-rating-color-default);
    }
    & > .bi-suit-heart::before{
        content: "\F59E" !important;
   }
}

.rating:hover label.rating-label {
    & > i,
    & > .svg-icon {
        color: var(--kt-primary);
    }
    & > .bi-suit-heart::before{
        content: "\f59d" !important;
   }
}

label.rating-label:hover ~ .rating-label {
    & > i,
    & > .svg-icon {
        color: var(--kt-rating-color-default);
    }
    color: var(--kt-rating-color-default);
    & > .bi-suit-heart::before{
        content: "\F59E" !important;
   }
}

label.rating-label:hover ~ .rating-label {
    & > i,
    & > .svg-icon {
        color: var(--kt-rating-color-default);
    }
    color: var(--kt-rating-color-default);
   
}

#receiptTotalsTable tr{
    line-height: 10px;
}

#receiptItemTable tr{
    line-height: 10px;
}
#receiptTicketDetailsTable tr{
    line-height: 10px;
}
#receiptPaymentTable tr{
    line-height: 10px;
}

.opacity05{
    opacity: 0.5;
}

.customTipBtnCurrency{
    position: absolute;
    z-index: 999;
    background: transparent;
    left: 52%;
    border: 0 none !important;
    vertical-align: middle;
    align-items: center;
    font-size: 1.1rem;
    display: flex;
    height: 100%;
}

.customBtnNoBg{
    background: transparent !important;
    color: var(--kt-card-color) !important;
}
.buttonNoRightBorderRadios{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.buttonNoLeftBorderRadios{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.small-padding{
    padding-left:2px !important;
    padding-right:2px !important;
}

.rounded-border{
    border: 1px solid;
    border-radius: 15px;
}