@media (max-width: 768px) {
  .menu-item-language {
    position: relative !important;
    .menu-sub-dropdown {
      position: absolute !important;
      top: 100% !important;
      left: 0 !important;
      transform: none !important;
    }
  }
}
