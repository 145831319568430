
.tns.tns-default .tns-outer {
    margin: 0px 0px !important;
}
.tns.tns-default [data-controls=prev] {
    left: 0 !important;
    font-size: 50px !important;
    background: var(--theme-bg-color);
    height: 100%;
    z-index: 2;
    padding: 5px;

}
.tns.tns-default [data-controls=next] {
    right: 0 !important;
    font-size: 50px !important;
    background: var(--theme-bg-color);
    height: 100%;
    z-index: 2;
}
.tns .tns-controls button:hover {
    background-color: var(--theme-bg-color);
}

.root-product {
    isolation: isolate;
}

.product-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0 8px;
}

.product-title {
    color:var(--kt-primary);
    display: inline;
}
.product-subheader {
    color: var(--kt-primary);
    display: inline;
    margin-left: 8px;
    white-space: nowrap;
}

.view-link {
    cursor: pointer;
    font-family: Inter,Helvetica,Arial,sans-serif;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
    font-size: 1.4rem;
    border: none;
    border-bottom: 2px solid var(--kt-primary);
    color: var(--kt-primary);
    display: inline;
    font-weight: bolder;
    padding: 0;
    position: relative;
    transition: color 200ms,border-bottom 200ms;
    text-decoration: none;
    margin-left: auto;
    white-space: nowrap;
}


.tns .tns-controls button {
    color: var(--kt-primary);
    margin: 0 !important;
    opacity: 0.7;
    padding:0 !important
}


.triggers {
  padding: 1rem;
}
.triggers ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
.triggers button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
  color: var(--kt-primary);
}
.triggers button.active {
  color: var(--kt-primary);
  border-bottom: 2px solid var(--kt-primary);
}

.sections {
  overflow: auto;
}
.sections section {
  padding: 1rem;
}

.section-start {
  scroll-margin-top: 115px;
  // min-height: 490px;
}
@media(min-width:1000px){
  // .section-start{
  //   min-height: calc(100vh - 50px);
  // }
}
.stickyNav::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.stickyNav {
  display: flex;
  // border-bottom: 1px solid #ddd;
  min-height: 54px;
  transition: opacity 275ms;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.sticky-cat-top{
    position: sticky !important;
    background-color: var(--theme-bg-color);
    z-index: 1020;
    overflow: hidden;
}
#sticky-separator-dashed {
  position: sticky !important;
  z-index: 1020;
  width: 100vw !important;
  top: 120px;
  margin-left: calc(-50vw + 49%);
  overflow: hidden;
}
@media(max-width:1000px){
  .sticky-cat-top {
      top: 119px !important;
  }
  #sticky-separator-dashed{
    top: 174px;
  }
  .sticky-search-top{
    top: 55px !important;
  }
}
.sticky-search-top {
  position: sticky !important;
  z-index: 1020;
}
[data-theme="dark"]{
  .sticky-search-top {
    background-color: #151521;
  }
}
[data-theme="light"]{
  .sticky-search-top {
  background-color: #f5f8fa;
  }
}
@media(max-width:1000px){
}
#stickyNav :first-child{
  padding-left: 0px !important;
}
#stickyNav :last-child{
  padding-right: 0px !important;
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .sticky-search-top{
    top: 64px !important;
  }
  .sticky-top{
      top: 64px !important;
  }
  .sticky-cat-top {
    top: 119px !important;
  }
  #sticky-separator-dashed{
    display: none;
  }
}
.max-width-menu-page {
  max-width: 1100px;
}

//-----------------------------------------------------------------------------------------------------// all-items-grid
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

/* Mobile devices (320px — 480px) */

@media screen and (min-width: 320px) and (max-width: 479px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    display: grid;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    display: grid;
    flex-wrap: wrap;
  }
 
}



#scroll-left {
    left: 0;
}
#scroll-right {
  right: 0;
}
#scroll-right, #scroll-left {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    padding: 0px;
    text-align: center;
    opacity: .7;
    transition: opacity .15s ease;
    font-size: 50px !important;
  background: var(--theme-bg-color);
  color: var(--kt-primary);
  outline: none;
  border: 0;
  margin: 0 0.25rem;
  border-radius: 0.475rem;
  
}

// .tns.tns-default .tns-outer {
//   margin: 0px 0px !important;
// }
// .tns.tns-default [data-controls=prev] {
//   left: 0 !important;
//   font-size: 50px !important;
//   background: var(--theme-bg-color);
//   height: 100%;
//   z-index: 2;
//   padding: 5px;

// }
// .tns.tns-default [data-controls=next] {
//   right: 0 !important;
//   font-size: 50px !important;
//   background: var(--theme-bg-color);
//   height: 100%;
//   z-index: 2;
// }
/* iPads, Tablets (481px — 768px) */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    display:grid;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Small screens, laptops (769px — 1024px) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    display:grid;
    flex-wrap: wrap;
    justify-content:flex-start;
  }
}

/* Desktops, large screens (1025px — 1200px) */
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Extra-large screens, TV (1201px and more) */
@media screen and (min-width: 1201px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

.scroll-arrow{
  position: absolute;
  display: flex;
  float: right;
  right: 0;
  height: 100%;
  width: 50px;
}