//
// Aside Default
//

// General mode(all devices)
.aside {
	display: flex;
	flex-direction: column;
	box-shadow: var(--kt-aside-box-shadow);
	padding: 0;

	// Custom button
	.btn-custom {
		display: flex;
		align-items: center;
		justify-content: center;		
		padding-left: 0;
		padding-right: 0;

		.btn-label {
			transition: opacity get($aside-config, transition-speed) ease;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;		
			opacity: 1;	
		}	

		.btn-icon {
			display: inline-block;
			transition: opacity get($aside-config, transition-speed) ease;
			overflow: hidden;		
			opacity: 0;	
			margin: 0;
			width: 0;
		}
	}

	// Dark theme
	&.aside-dark {
		background-color: get($aside-config, bg-color-dark);

		// Logo
		.aside-logo {
			background-color: get($aside-config, logo-bg-color);
		}

		// Aside toggle
		.aside-toggle {
			color: #494b74;
		}

		// Separator
		.separator {
			border-bottom-color: #282a3d;
		}

		// Custom button
		.btn-custom {
			@include button-custom-variant(
				$color: #B5B5C3, 
				$icon-color: null, 
				$border-color: null, 
				$bg-color: rgba(63, 66, 84, 0.35), 
				$color-active: #B5B5C3, 
				$icon-color-active: null, 
				$border-color-active: null, 
				$bg-color-active: rgba(63, 66, 84, 0.35)
			);

			.btn-icon {				
				&,
				i,
				.svg-icon {
					color: #B5B5C3;
				}
			}
		}
	}

	// Light theme
	&.aside-light {
		background-color: var(--kt-aside-bg-color);
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		width: get($aside-config, width);
		transition: width get($aside-config, transition-speed) ease;

		// Logo
		.aside-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: get($header-config, default, height, desktop);
			padding: 0 get($aside-config, padding-x);
		}

		// Aside menu
		.aside-menu {
			width: get($aside-config, width); 
		}
		
		// Fixed aside mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, z-index);
			overflow: hidden;
		}

		// Static aside mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Minimize aside mode
		[data-kt-aside-minimize="on"] & {
			width: get($aside-config, minimized-width);
			transition: width get($aside-config, transition-speed) ease;

			// Logo
			.aside-logo {
				.logo-default {
					display: inline-block;
				}
	
				.logo-minimize {
					display: none;
				}
			}

			// Hoverable
			&.aside-hoverable:hover:not(.animating) {
				transition: width get($aside-config, transition-speed) ease;
				width: get($aside-config, width);
				box-shadow: var(--kt-aside-minimized-hover-box-shadow);
			}

			// Not hovered mode
			&:not(.aside-hoverable),
			&:not(:hover) {
				// Logo
				.aside-logo {
					justify-content: center;

					.logo {
						display: none;
					}

					.logo-default {
						display: none;
					}
		
					.logo-minimize {
						display: inline-block;
					}
				}				

				// Menu
				.aside-menu {
					.menu-content,
					.menu-title {
						opacity: 0;
						transition: opacity get($aside-config, transition-speed) ease;
					}

					.menu-item.show > .menu-sub {
						height: 0;
						overflow: hidden;
						transition: height get($aside-config, transition-speed) ease;
					}
				}

				// Custom button
				.btn-custom {
					.btn-label {
						width: 0;
						opacity: 0;
						transition: opacity get($aside-config, transition-speed) ease;
					}	

					.btn-icon {
						width: auto;
						opacity: 1;
						transition: opacity get($aside-config, transition-speed) ease;
					}
				}
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;
		// Logo
		.aside-logo {
			display: none;
		}
	}
}

#basic-addon1, #basic-addon2, #kt_inputmask_1{
	border-color: transparent;
}
.react-select-flags{
	width: 110px !important;
}
#basic-addon1 > div > ul {
	margin-top: 0;
	min-height: 195px;
}
#basic-addon1 > div > ul > li {
	padding: 9px 15px;
	:last-child {
		color: '#92929f';
	}
}
#basic-addon2 > div > div > ul > li {
	padding: 9px 15px;
	:last-child {
		color: '#92929f'!important;
	}
	color: #5E6278 !important;
}
//styles selectable items in list for phone number flag dropdown
#basic-addon1 > div > ul > li:hover {
	background-color: var(--kt-primary-light);
	color: $primary;
	:last-child {
		color: $primary;
	}
}
#basic-addon2 > div > ul > li:hover {
	background-color: var(--kt-primary-light);
	color: $primary;
	:last-child {
		color: $primary;
	}
}
#basic-addon1 > div > ul > li:active{
	background-color: $primary;
	color: var(--kt-body-color);
	:last-child {
		color: var(--kt-body-color);
	}
    background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
    background-size:.8rem;
    background-position: center right 1.25rem;
}

#basic-addon2 > div > ul > li:active{
	background-color: $primary;
	color: var(--kt-body-color);
	:last-child {
		color: var(--kt-body-color);
	}
    background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
    background-size:.8rem;
    background-position: center right 1.25rem;
}


.activeCallCodeID{
	background-color: $primary;
	color: var(--kt-body-color);
	:last-child {
		color: var(--kt-body-color);
	}
    background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
    background-size:.8rem;
    background-position: center right 1.25rem;
}

//styles parent element for input
#basic-addon1 > div > ul > div {
	background-color: var(--bs-gray-100) !important;
	margin-bottom: 12px;
	border: none;
}

#basic-addon2 > div > ul > div {
	background-color: var(--bs-gray-100) !important;
	margin-bottom: 12px;
	border: none;
}
//input styles for flag dropdown input field
#basic-addon1 > div > ul > div > input {
	background-color: var(--bs-gray-100) !important;
	padding: 6px 15px;
	margin-top: .5rem;
	border: 1px solid #323248;
	border-radius: 0.475rem;
	color: var(--kt-body-color)
} 

#basic-addon2 > div > ul > div > input {
	background-color: var(--bs-gray-100) !important;
	padding: 6px 15px;
	margin-top: .5rem;
	border: 1px solid #323248;
	border-radius: 0.475rem;
	color: var(--kt-body-color)
} 
//placholder styles
#basic-addon1 > div > ul > div > input::placeholder {
	color: transparent;
}

#basic-addon2 > div > ul > div > input::placeholder {
	color: transparent;
}

button[aria-expanded="true"] + ul {
    background-color: var(--bs-gray-100) !important;
	border: none;
	font-size: 1.15rem !important;
	font-weight: normal;
}
[data-theme="dark"]{
	#kt_inputmask_1:focus{
		background-color: #171723;
	}
	button[aria-expanded="true"]{
		background-color: #171723;
		border: none;
		:last-child {
			position:'fixed';
		}
	}
	.customInputBackground:focus{
		background-color: #171723 !important;
	}
	.activeCallCodeID{
		color: white !important;
	}
	.theme-bg{
		background-color: #151521;
	}
	.input-group-text:focus-within {
	background-color: inherit !important;
	}
}
.svg-cart-theme-styles{
text-align: center;
background-color: var(--kt-primary);
}
.svg-cart-theme-styles > .svg-icon{
	color: #ffffff
}
[data-theme="light"]{
	button[aria-expanded="true"]{
		background-color: #eef3f7;
		border: none;
		:last-child {
			position:'fixed';
		}
	}
	#kt_inputmask_1:focus{
		background-color: #eef3f7;
	}
	.customInputBackground:focus{
		background-color: #eef3f7 !important;
	}
	.activeCallCodeID{
		color: black !important;
	}
	.theme-bg{
		background-color: #ffffff;
	}
	 
	.input-group-text:focus-within{
	background-color: #eef3f7 !important; 
	}
}
button[aria-expanded="false"]{
	border: none;
	line-height: initial;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
.card-border-width-mixed-widget2{
	border-color: var(--kt-text-primary);
	border-style: solid;
	border-width: 1px;
}
.genderMenuOptions{
	padding: 9px 15px;
}
.dropdown-item {
	color: #92929F !important;
}
.dropdown-item:hover{
	background-color: var(--kt-primary-light);
	color: $primary !important;
}

@media (max-width: 1500px) {
	#genderForm{
		width: 93%;
	}
}
@media (min-width: 1501px) {
	#genderForm{
		width: 96%;
	}
}
#footerLink{
	color: #7d7a9bcd !important;
}
#softpoint-footer-link {
	&:hover {
        color: #3699ff !important;
    }
}
.footer-menu-link > a{
	color: #6d6d80 !important;
	&:hover {
        color: #3699ff !important;
    }
}
#countryList {
	padding-bottom: 0px;
}
#countryList-MyAccount{
	pointer-events: none !important;
}
#countryList-MyAccount > button:after {
	content: none !important;
}
#basic-addon2 > div > button[aria-expanded="false"] > span > span:nth-child(3) {
	visibility: hidden !important;
}
#basic-addon2 > div > button[aria-expanded="true"] > span > span:nth-child(3) {
	visibility: hidden !important;
}
.text-theme-color{
		color: var(--theme-text-color) !important;
	}
.ccnum-style{
	//add CC background img thru JSX
	width: 100%;
    display: inline;
    margin-right: 10px;
	background-position: 98%; 
	background-repeat: no-repeat;
	background-size: contain;
}
.active-tip-btn {
	background-color: var(--kt-primary) !important;
}
.active-tip-btn:hover{
	background-color: var(--kt-primary) !important;
}
#no-tip-button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
    background-color: var(--kt-input-solid-bg) !important;
}
#tip-input-group-text {
	border: none !important;
}
#custom-tip-input, #end-button-style {
	border-top-right-radius: var(--bs-border-radius);
	border-bottom-right-radius:  var(--bs-border-radius);
}
.no-border-radius {
	border-radius: 0;
}
.order-type-dropdown {
	position: relative;
	width: 200px;
}
.order-type-dropdown-toggle {
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	height: 42.5px;
	background-color: var(--kt-input-solid-bg) !important;
}
.order-type-dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	background-color: var(--kt-input-solid-bg) !important;
	border-radius: 4px;
	min-width: 200px;
}
.order-type-dropdown-item {
	padding: 10px;
	cursor: pointer;
}
.order-type-dropdown-item:hover {
	background-color: var(--kt-primary-light) !important;
	color: $primary !important;
}