#StoreBanner {
  border-radius: 16px;
  overflow: hidden;
}

#ImgStoreBanner {
  object-fit: cover;
  width: 100%;
}

#StoreLogo {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    height: 80px;
    inline-size: 80px;
    left: 16px;
    position: absolute;
    background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    overflow: hidden;
}

#ImgStoreLogo {
    height: 76px;
    object-fit: contain;
    width: 76px;
}