#CameraPageId {
  text-align: center;
  .video-selfie {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(calc((100% - 100vw) / 2));
  }

  .svgClass {
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    bottom: 0;
  }

  .button{
    position: absolute;
    top: 50%;
    right: 7%;
    z-index: 1;
    font-size: 50px;
    &:hover {
      color: #00A3FF;
    }
    span {
      font-size: 20px;
    }
  }
}
// #xCloseIdTakePhoto{
//     color: #ffff;
//     z-index: 1;
//     font-size: 25px;
//     margin-top: -50px;
//     cursor: pointer;
//     right: 8%;
//     position: absolute;
// }
// .centerIdTakePhotoText{
//     color: white;
//     z-index: 1;
//     position: absolute;
//     top: 10px;
//     // right: -16%;
//     right: 50%;
//     font-size: 25px;
//     // transform: rotate(-90deg);
//   }
// @media only screen and (min-width: 320px) and (max-width: 768px) {
//   .centerIdTakePhotoText{
//     right: 40%;
//     }
// }
// @media only screen and (min-width: 769px) and (max-width: 1500px) {
//   .centerIdTakePhotoText{
//       right: 40%;
//     }
// }
// @media only screen and (min-width: 1501px) {
//   .centerIdTakePhotoText{
//       right: 45%;
//     }
// }


.RotateDeviceTakePhotoText{
  color: white;
  z-index: 1;
  position: absolute;
  top: 2px;
  // right: -16%;
  right: 50%;
  margin-top: 10px;
  z-index: 1;
  font-size: 25px;
  transform: rotate(90deg);
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
.RotateDeviceTakePhotoText{
    right: -25%;
    top: 50%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1500px) {
.RotateDeviceTakePhotoText{
  right: -12%;
  top: 48%;
  font-size: 35px;
  }
}
@media only screen and (min-width: 1501px) {
.RotateDeviceTakePhotoText{
  right: -2%;
  top: 45%;
  font-size: 40px;
  }
}

.centerFaceText , .centerIdTakePhotoText{
  color: white;
  z-index: 1;
  font-size: 25px;
  // margin-top: -50px;
}

.xClose, #xCloseIdTakePhoto{
  color: #ffff;
  z-index: 1;
  font-size: 25px;
  cursor: pointer;
  right: 9%;
  position: absolute;
}