.swal2-icon {

	// Error
	&.swal2-error {
    	border-color: var(--kt-danger);
    	color: var(--kt-danger);

		[class^=swal2-x-mark-line] {
			background-color: rgba(var(--kt-danger-rgb), 0.75);
		}
	}
}

.mx-20px {
    margin-right: 20px !important;
    margin-left: 20px !important;
}
.px-10px {
    padding-right: 10px;
    padding-left: 10px;
}
.mt-20px {
    margin-top: 20px !important;
}
.mb-20px {
    margin-bottom: 20px !important;
}
.aside-fixed .aside {
    z-index: 2001;
}

.input-group-text {
    border-radius: 0.625rem;
	// border: 1px solid var(--kt-input-border-color) !important;
}

.country-code-name #countryList ul {
	min-width: 330px !important;
}

/* Mobile devices (320px — 480px) */

@media screen and (min-width: 320px) and (max-width: 479px) {
	.country-code-name #countryList ul {
		min-width: 305px !important;
	}
  }


  /* iPads, Tablets (481px — 768px) */
  @media screen and (min-width: 481px) and (max-width: 768px) {
	.country-code-name #countryList ul {
		min-width: 305px !important;
	}
  }

  /* Small screens, laptops (769px — 1024px) */
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }

  /* Desktops, large screens (1025px — 1200px) */
  @media screen and (min-width: 1025px) and (max-width: 1200px) {

  }

  #basic-addon1 > div > ul {
	margin-bottom: 0px !important;
}


.custom-item-count-3-digit{
    align-items: center;
    border-radius: 100%;
    border: 2px solid;
    display: flex;
    height: 25px;
    font-size: 10px;
    justify-content: center;
    width: 25px;
    color: #ffffff;
}
.custom-item-count-2-digit{
    align-items: center;
    border-radius: 100%;
    border: 2px solid;
    display: flex;
    height: 22px;
	font-weight: 600;
    font-size: 12px;
    justify-content: center;
    width: 22px;
    color: #ffffff;

.SelfieStyleBorderIsFalse {
  border: dashed 1px var(--kt-danger);
}

}

.customBtnNoBg {
    .transparent-btn-white-color{
        color: #ffffff;
    }
    .transparent-btn-black-color{
        color: #151520;
    }
     &:hover{
        .transparent-btn-white-color{
            color: #151520;
        }
        .transparent-btn-black-color{
            color: #ffffff;
        }
    }&:active{
        .transparent-btn-white-color{
            color: #151520;
        }
        .transparent-btn-black-color{
            color: #ffffff;
        }
    }
}
.customBtnNoBg.active {
    .transparent-btn-white-color{
        color: #151520;
    }
    .transparent-btn-black-color{
        color: #ffffff;
    }
}

.anchorLink {
    color: #009ef7 !important;  
} 
.anchorLink:hover, .anchorLink:active {
    color: #009ef7 !important;  
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

.dropdown-caret-hide:after{
    border-top: 0px !important;
}

.badge {
    display: inline-block;
    padding: .5em .85em;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .475rem
}

.dropdownFlagSelectButton {
    background-color: var(--kt-input-solid-bg);
    border-color: var(--kt-input-solid-bg);
    color: var(--kt-input-solid-color) !important;
    transition: color 0.2s ease;
    border-radius: 0.625rem;
    font-size: 1.10rem !important;
    font-weight: 500;
    padding: 8px 10px;
}

.dropdownFlagSelectButton:disabled {
    background-color: var(--kt-input-disabled-bg) !important;
    border:1px solid var(--kt-input-disabled-border-color) !important;
}

.dropdownFlagSelectButton > span > *:last-child {
    padding-top: 2px;
}