//
// Scrolltop
//

#kt_cbar_mobile_toggle > .svg-icon{
    display: inline-block !important;
    float: left !important;
}
.cartIconFooterBtn{
    height: 22px !important;
    width: 22px !important;
}

.btn-footer-cart{
    position: fixed;
    left: 50%;
    margin-left: -175px;
    bottom: 0 !important;
    width: 350px;
}

.scrolltop {  
    bottom:96px !important;  
    background-color: var(--kt-primary);
    
    .svg-icon {
        color: var(--kt-primary);
    }
    
    > i {
        font-size: 1.3rem;
        color: 'white';
    }

    &:hover {
        background-color: var(--kt-primary);

        .svg-icon,
        i {
            color: var(--kt-primary);
        }
    }
    
}
