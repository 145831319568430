//
// BlockUI
//


.blockui  {
    position: relative;

    .blockui-overlay {
        transition: all 0.3s ease;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--kt-blockui-overlay-bg);

        .spinner-border {
            height: 2.35rem;
            width: 2.35rem;
            color: var(--kt-primary);
        }
    }

    .blockui-message {
        display: flex;
        align-items: center;
        @include border-radius($border-radius);
        box-shadow: var(--kt-dropdown-box-shadow);
        background-color: var(--kt-tooltip-bg);
        color: var(--kt-gray-700);
        font-weight: $font-weight-bold;
        margin: 0 !important;
        width: auto;
        padding: 0.85rem 1.75rem !important;

        .spinner-border {
            margin-right: 0.65rem;
        }
    }
}
